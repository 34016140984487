import React, { useState } from 'react';
// import "../CSS/navbarnew.css"
import { Link } from 'react-router-dom';
import '../src/assets/navbarnew.css'
// import logo from "../Photos/logo.png"
// import logo1 from "../Photos/Skanslogo1.png"
// import logo2 from "../Photos/Skanslogo2.png"
// import logo3 from "../Photos/30yearslogo.png"
// import GlobalSidebar from './GlobalSideBar';
// import employability from "../Pdf/emp-ability.pdf"
// import IsbPlatinumCertificate from "../Photos/IsbPlatinumCertificate.jpg"
// import RwpPlatinumCertificate from "../Pdf/RwpPlatinumCertificate.pdf"
// import ICAPfellowREAT from "../Photos/ICAPfellowREAT.jpg"
// import CBELicense from "../Pdf/CBE licence SKANS Rawalpindi 2024-2025.pdf"
// import CAAffiliation from "../Pdf/CA Affilliation certificate.pdf"
// import GoldApprovedLeraningPartner from "../Pdf/SKANS - Gold Approved Learing Partner Certificate.pdf"
// import STCampusCBELicence from "../Pdf/ST Campus CBE Licence.pdf"



// import Popup1 from './Popup1';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import Popup2 from './Popup2';
// import { backendURL } from '../constant';





function NavbarNew() {
    // const [isMenuDisplayed, setIsMenuDisplayed] = useState(false);

    // const toggleMenu = () => {
    //     setIsMenuDisplayed(!isMenuDisplayed);
    // };
    // const [isOpen1, setIsOpen1] = useState(false);



    // const togglePopup3 = () => {
    //     setIsOpen1(!isOpen1);
    // }



    // const [showMenu, setShowMenu] = useState(false);

    // const toggleMenu = () => {
    //     setShowMenu(!showMenu);
    // };


    // const handleClose = () => setShowMenu(false);
    // const handleShow = () => setShowMenu(true);

    // const [Complaint, setComplaint] = useState({
    //     name: "",
    //     email: "",
    //     mobile: "",
    //     campus: "",
    //     complaint: ""
    // });
    // const [detail, setDetail] = useState({});

    // const handleInput = (e) => {
    //     const { name, value } = e.target;
    //     setComplaint({ ...Complaint, [name]: value });
    // };

    // const handleDataUpload = async (e) => {
    //     e.preventDefault();

    //     try {
    //         const response = await axios.post(`${backendURL}/ComplaintForm`, Complaint);
    //         console.log(response);
    //         toast.success("Your Complaint saved Successfully.", {
    //             position: "top-center",
    //             hideProgressBar: true,
    //             theme: "colored",
    //         });
    //         setComplaint({
    //             name: "",
    //             email: "",
    //             mobile: "",
    //             campus: "",
    //             complaint: ""
    //         }); // Clear the input fields after successful upload
    //     } catch (error) {
    //         console.error(error);
    //         toast.error("Failed to Upload Complaint", {
    //             position: "top-center",
    //             hideProgressBar: true,
    //             theme: "colored",
    //         });
    //     }
    // };

    return (
        <>
            {/* <ToastContainer /> */}
            <div className=' rounded-0 bgdarkblue pe-5-lg p-0 z-5 d-lg-block d-none' >
                <ul className='exo-menu list-unstyled d-flex justify-content-center  mb-0 '>

                    <li className="drop-down zindex5 border-primary border border-start-0 border-top-0 border-bottom-0">
                        <a className='px-2 py-1' >Certificates &nbsp;&nbsp; <i className='bi bi-chevron-down'></i></a>
                        <ul className="drop-down-ul animated fadeIn ">
                            <li className=""><a target="_blank"  style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Employability</a>
                            </li>

                            <li className=""><a target="_blank"  style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>SKANS ISLAMABAD Platinum ALP Certificate</a>

                            </li>
                            <li className=""><a target="_blank"  style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>SKANS RAWALPINDI Platinum ALP Certificate</a>

                            </li>
                            <li className=""><a target="_blank"  style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CBE licence SKANS Rawalpindi 2024-2025</a></li>

                            <li className=""><a target="_blank"  style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>ICAP Fellow REAT Certificate</a>


                            </li>
                            <li className=""><a target="_blank"  style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CA Affilliation Certificate</a></li>
                                    <li className=""><a target="_blank"  style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>ST Campus CBE Licence</a></li>
                                    <li className=""><a target="_blank"  style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>SKANS - Gold Approved Learing Partner Certificate</a></li>

                        </ul>
                    </li>

                    <li className="drop-down zindex5 border-primary border border-start-0 border-top-0 border-bottom-0">
                        <a className='px-2 py-1' >SKANS Login &nbsp;&nbsp; <i className='bi bi-chevron-down'></i></a>
                        <ul className="drop-down-ul animated fadeIn ">
                            <li className=""><a href="/HowtouseStudentportal" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>How to use our Student Portal</a>
                            </li>

                            <li className=""><a href="http://58.65.172.36/stdlogin/login.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Student Portal</a>

                            </li>

                        </ul>
                    </li>



                    <li className='px-4 border-primary  border border-start-0 border-top-0 border-bottom-0 py-1'>
                        <a className='text-decoration-none text-white' href="http://58.65.172.36/cb%20result/login.aspx">Teacher Evaluation</a>

                    </li>
                    <li className='px-4 border-primary  border border-start-0 border-top-0 border-bottom-0 py-1'>
                        <a className='text-decoration-none text-white' href="/Applyonline">Apply Online</a>

                    </li>



                    <li className="drop-down zindex5 border-primary border border-start-0 border-top-0 border-bottom-0">
                        <a className='px-2 py-1' >CBE Practice &nbsp;&nbsp; <i className='bi bi-chevron-down'></i></a>
                        <ul className="drop-down-ul animated fadeIn ">
                            <li className=""><a href="http://58.65.172.36/PRCcbe/default.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CA-PRC/AFC</a></li>
                            <li className=""><a href="http://58.65.172.36/cafcbe/default.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CA-CAF</a></li>
                            <li className=""><a href="http://58.65.172.36/fdacbe/default.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>FDA/ACCA</a></li>
                            <li className=""><a href="http://58.65.172.36/accacbe/default.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>ACCA Skill Level</a></li>



                        </ul>
                    </li>
                    <li className='px-4 border-primary  border border-start-0 border-top-0 border-bottom-0 py-1'><a className='' href="http://58.65.172.36/WebSiteUpdates/NoticeBoard.aspx">CBE Dates</a></li>


                    <li className='px-4 border-primary  border border-start-0 border-top-0 border-bottom-0 py-1'>
                        <a className='text-decoration-none text-white' >Complaint box</a>

                    </li>
                    <li className='px-4 border-primary  border border-start-0 border-top-0 border-bottom-0 py-1'><a style={{fontSize:"14px"}} href='https://skans.pk/onlinepayments/challans.php'>Online Challan Payment</a></li>

                </ul>


            


            </div>







    


        </>
    );
}

export default NavbarNew;
