import React, { useState } from "react";
import "./App.css"; // Add your CSS file for styling
import Home from "./Home";
import SchoolTour from "./SchoolVirtualTour";
import { Route, Routes } from "react-router-dom";
import Contact from "./Contact";
import MeritStudents from "./HighAchiever";
import CollegeTour from "./CollegeVirtualTour";


const App = () => {

  return (
    <>
       <Routes>
    <Route exact path="/" element={<Home />} />
    <Route path="/SchoolTour" element={<SchoolTour />} />
    <Route path="/CollegeTour" element={<CollegeTour />} />

    <Route path="/Contact" element={<Contact />} />
    <Route path="/highAchiever" element={<MeritStudents />} />


    
    </Routes>
      
    </>
  );
};



export default App;
