import React from 'react'
// import "../CSS/imagehover.css"
import "./assets/collegetour.css"
import "./assets/schooltourCard.css"
import "./assets/GulzareQuaid.css"


import { Link } from 'react-router-dom'
import NavbarNew from './navbar';
import Footer from './Footer'
// import Sectiontitle1 from '../Photos/section-title-shape-1.png';
// import Sectiontitle2 from '../Photos/section-title-shape-2.png';
// import SchoolNavbar from '../Components/SchoolNavbar';



function Contact() {
    return (


        <div className="index-page">
        <NavbarNew />
        <Header />
        <main className="main">
        <div>
            {/* <SchoolNavbar/> */}
            <div className='contactbg'>
                <div className='  container-fluid  ps-lg-5 px-0 text-light filter6 text-center'>
                    <div className='d-flex flex-column'>

                        <div>
                            <span className='fw-bold fs-md-1 fs-2 mb-5'>
                                __________________________
                            </span>
                            <h1 className='text-white fw-bold text-uppercase pt-4 mb-0 d-md-block d-none'><i class="bi bi-stars fs-1 fw-bold pe-3"></i>Contact Us <i class="bi bi-stars fs-1 fw-bold ps-3"></i></h1>
                            <h2 className='text-white fw-bold text-uppercase pt-4 mb-0 d-md-none d-block'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>Contact Us <i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h2>


                            <div className='fw-bold fs-md-1 fs-2 text-center'>
                                __________________________
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='container mt-5 lh-lg'>
                <p>At SKANS, we are dedicated to supporting our students, parents, and community at every step of the educational journey. Whether you have a question, need assistance, or simply want to learn more about our programs, we’re here to help!</p>
            </div>
            <section class="light">
	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle">Our Campuses (SCHOOLS)</div>

		<article class=" postcard light blue">
        <div className='col-6'>
                        <iframe data-aos="fade-up" className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3403920.547683911!2d73.043105!3d33.575684!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df9376ee69112d%3A0x56f5e38beb6c2f75!2sSKANS%20Schools%20System%20Harley%20Campus!5e0!3m2!1sen!2sus!4v1692607144941!5m2!1sen!2sus" width="500" height="300" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
			<div class="postcard__text1 t-dark">
				<h1 class="postcard__title dark-blue fw-bold"><a href="#">Harley Street Campus</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="fas fa-calendar-alt mr-2"></i>Junior School
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">House No 53-B Harley Street Campus – Rawalpindi</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"> </i>  (+92)-332-8922225</li>
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>(+92)-51-5176138</li>
					<li class="tag__item">
						<a href="#"><i class="bi bi-phone mr-2"></i>(+92)-51-5176066</a>
					</li>
				</ul>
                <a className='fw-bold' href="https://hscampus.skansschool.edu.pk/">Visit Website</a>
			</div>
		</article>
        
		<article class=" postcard light red">

			<div class="postcard__text1 t-dark">
				<h1 class="postcard__title red fw-bold"><a href="#">AECHS Campus</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="bi bi-calendar-alt mr-2"></i>Junior School
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">337 Street 17, Sector 1 Airport Employees CHS, Rawalpindi</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>(051) 8468475</li>

				</ul>
                <a className='fw-bold' href="https://aechscampus.skansschool.edu.pk/ ">Visit Website</a>
			</div>
            <div className='col-6'>
                    <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d106308.90435853692!2d73.01587275164856!3d33.62726792723422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38dfedd25af0b447%3A0xad845b487020e809!2sSkans%20School%20System%20AECHS%20Campus%2C%20337%20Street%2017%2C%20Sector%201%20Airport%20Employees%20CHS%2C%20Rawalpindi%2C%20Punjab%2046000!3m2!1d33.5880636!2d73.129655!5e0!3m2!1sen!2s!4v1712123400984!5m2!1sen!2s"  width="500" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                    </div>
		</article>
		<article class=" postcard light green">
        <div className='col-6'>
                    <iframe data-aos="fade-up" className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26578.369335647058!2d72.965729!3d33.623562!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df971f4accbb67%3A0x548fe651929da710!2sSKANS%20International%20Islamic%20School&#39;s!5e0!3m2!1sen!2sus!4v1697112087637!5m2!1sen!2sus" width="500" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                       
                    </div>
			<div class="postcard__text1 t-dark">
				<h1 class="postcard__title green fw-bold"><a href="#">Golra Mor Campus</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="bi bi-calendar-alt mr-2"></i>Junior School
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Moaz Rd, Main Peshawar Rd, near Jamia Masjid Suqaina Golrah Morh, Islamabad</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>(051) 2227475</li>
				</ul>
                <a className='fw-bold' href="https://golrajuniorcampus.skansschool.edu.pk/">Visit Website</a>
			</div>
		</article>
		<article class="postcard light yellow">

			<div class="postcard__text1 t-dark">
				<h1 class="postcard__title yellow fw-bold"><a href="#">Chakri Road Campus</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="bi bi-calendar-alt mr-2"></i>Junior School
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Raja Bashir Plaza, Moza Hayal Ranail, Opposite Pir Mehar Ali Shah Town, Chakri Road Rawalpindi.</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>0310-3331131</li>
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>051- 5575082</li>
				</ul>
                <a className='fw-bold' href="http://chakricampus.skansschool.edu.pk/">Visit Website</a>
			</div>
            <div className='col-6'>
                    <iframe data-aos="fade-up" className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6650.6831926709665!2d73.000458!3d33.5445!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df935b355c1845%3A0xda355a4a1a330808!2sSkans%20school%20system!5e0!3m2!1sen!2sus!4v1697111177831!5m2!1sen!2sus" width="500" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
		</article>


    <article class="postcard light yellow">
    <div className='col-6'>
                        <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d106349.85796825575!2d72.978106950164!3d33.594066440527165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38df95002ace9d01%3A0xc61fcce66fdef971!2sSkans%20International%20Islamic%20School%20Class%201-2%2C%20310A%20I%20Service%20Rd%2C%20Westridge%201%2C%20Rawalpindi%2C%20Punjab%2046000!3m2!1d33.605236!2d73.023287!5e0!3m2!1sen!2s!4v1714917769259!5m2!1sen!2s" width="500" height="300" style={{ border: 0, borderRadius: "0px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
<div class="postcard__text t-dark">
  <h1 class="postcard__title yellow fw-bold"><a href="#">Junior Westridge Campus</a></h1>
  <div class="postcard__subtitle small">
    <time datetime="2020-05-25 12:00:00">
      <i class="bi bi-calendar-alt mr-2"></i>Junior School
    </time>
  </div>
  <div class="postcard__bar"></div>
  <div class="postcard__preview-txt">H.No. 9-B, Hali Road, Westridge-1, Rawalpindi</div>
  <ul class="postcard__tagbox">
    <li class="tag__item"><i class="bi bi-phone mr-2"></i>051-8773091</li>
    {/* <li class="tag__item"><i class="bi bi-phone mr-2"></i>051- 5575082</li> */}
  </ul>

          <a className='fw-bold' href="https://westridgejuniorcampus.skansschool.edu.pk/">Visit Website</a>
</div>			

</article>

<article class="postcard light yellow">

<div class="postcard__text t-dark">
  <h1 class="postcard__title yellow fw-bold"><a href="#">Senior Westridge Campus</a></h1>
  <div class="postcard__subtitle small">
    <time datetime="2020-05-25 12:00:00">
      <i class="bi bi-calendar-alt mr-2"></i>Senior School
    </time>
  </div>
  <div class="postcard__bar"></div>
  <div class="postcard__preview-txt">91 Hali Road, Street no. 8, Westridge-1, Rawalpindi</div>
  <ul class="postcard__tagbox">
    <li class="tag__item"><i class="bi bi-phone mr-2"></i>(051) 8355546</li>
    {/* <li class="tag__item"><i class="bi bi-phone mr-2"></i>051- 5575082</li> */}
  </ul>
          <a className='fw-bold' href=" https://westridgeseniorcampus.skansschool.edu.pk/">Visit Website</a>
</div>	
<div className='col-6 '>
                    <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d7403.733333034863!2d73.01424069182859!3d33.611141944357016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d33.6392986!2d73.0772357!4m5!1s0x38df95454cae1491%3A0xe0e8f7942ac9253!2sHouse%20no%2C%20Skans%20International%20Islamic%20School%2C%20310-A%20Main%20Peshawar%20Rd%2C%20Westridge%201%2C%20Rawalpindi!3m2!1d33.6066394!2d73.0184862!5e0!3m2!1sen!2s!4v1715544844250!5m2!1sen!2s" width="500" height="300"  style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                        {/* <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d106338.0499483005!2d72.94330480059205!3d33.603642286689485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38df95ca5f4bb601%3A0x18bf86bcf8bfd9b1!2sJ23G%2BC7J%2C%20Rawalpindi%2C%20Punjab%2046000!3m2!1d33.603669599999996!2d73.025706!5e0!3m2!1sen!2s!4v1711686353545!5m2!1sen!2s" width="500" height="300" style={{ border: 0, borderRadius: "0px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    </div>

</article>

<article class="postcard light yellow">
<div className='d-flex align-items-center col-12 col-lg-6'>

<iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d106318.69717320455!2d73.02391750129357!3d33.61933143040843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38dfed431e1b520d%3A0xf2f2788e3d47e5c2!2sskans%20pwd%20campus!3m2!1d33.5728222!2d73.14698539999999!5e0!3m2!1sen!2s!4v1712122103531!5m2!1sen!2s" width="500" height="300"  style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


</div>
<div class="postcard__text t-dark">
  <h1 class="postcard__title yellow fw-bold"><a href="#">PWD Campus</a></h1>
  <div class="postcard__subtitle small">
    <time datetime="2020-05-25 12:00:00">
      <i class="bi bi-calendar-alt mr-2"></i>Junior School
    </time>
  </div>
  <div class="postcard__bar"></div>
  <div class="postcard__preview-txt">233, Block A Sector A PWD, Islamabad, Punjab</div>
  <ul class="postcard__tagbox">
    <li class="tag__item"><i class="bi bi-phone mr-2"></i>(051) 8355546</li>
    {/* <li class="tag__item"><i class="bi bi-phone mr-2"></i>051- 5575082</li> */}
  </ul>
          <a className='fw-bold' href="https://pwdcampus.skansschool.edu.pk/">Visit Website</a>
</div>	


</article>



	</div>
</section>

<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle">Our Campuses (COLLEGES)</div>

		<article class=" postcard light blue">
    <div className=' col-6 '>
                        <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13275.589552305766!2d73.035687!3d33.711604!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbe531e328d2d%3A0x2d7c5767270ff30!2sSkans%20School%20of%20Accountancy!5e0!3m2!1sen!2sus!4v1696331851547!5m2!1sen!2sus" width="500" height="300" style={{ border: 0, borderRadius: "0px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title dark-blue fw-bold"><a href="#">F8 Campus, Islamabad</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="fas fa-calendar-alt mr-2"></i>College
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">14A-Haroon Building F8, Markaz Islamabad</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"> </i>(+92)-51-2287313</li>
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>(+92)-51-2287315</li>
					{/* <li class="tag__item">
						<a href="#"><i class="bi bi-phone mr-2"></i>(+92)-51-5176066</a>
					</li> */}
				</ul>
                {/* <a className='fw-bold' href="https://hscampus.skansschool.edu.pk/">Visit Website</a> */}
			</div>
		</article>
        
		<article class=" postcard light red">

			<div class="postcard__text t-dark">
				<h1 class="postcard__title red fw-bold"><a href="#">RAR Campus</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="bi bi-calendar-alt mr-2"></i>College
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">16-Raja Akram Road, Saddar, Rawalpindi Cantt</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>(+92)-51-512514849</li>
          <li class="tag__item"><i class="bi bi-phone mr-2"></i>(+92)-51-5125155</li>

				</ul>
                {/* <a className='fw-bold' href="https://aechscampus.skansschool.edu.pk/ ">Visit Website</a> */}
			</div>			
      <div className='col-6 '>
                        <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13292.449289700802!2d73.039474!3d33.60239!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df946297a95cf3%3A0x8aead128e41e7ea8!2sSKANS%20School%20of%20Accountancy!5e0!3m2!1sen!2sus!4v1696331964422!5m2!1sen!2sus" width="500" height="300" style={{ border: 0, borderRadius: "0px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
		</article>
		<article class=" postcard light green">
    <div className='d-flex align-items-center col-12 col-lg-6 '>
                        <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d53147.89052576665!2d73.07676700000002!3d33.637901!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df955662cb3185%3A0x560236c8cbc6bf77!2sSKANS%20School%20of%20Accountancy%20New%20Campus!5e0!3m2!1sen!2sus!4v1696332039984!5m2!1sen!2sus" width="500" height="300" style={{ border: 0, borderRadius: "0px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title green fw-bold"><a href="#">Satellite Town Campus</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="bi bi-calendar-alt mr-2"></i>College
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">House No. 77-A,Block A,Satellite Town Rehmanabad, Rawalpindi</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>(+92)-51-2716089</li>
				</ul>
                {/* <a className='fw-bold' href="https://golrajuniorcampus.skansschool.edu.pk/">Visit Website</a> */}
			</div>
		</article>
		
	</div>
        </div>
        </main>
        <Footer/>

        </div>
    )
}
const Header = () => {
    return (
      <header id="header" className="header d-flex align-items-center fixed-top ">
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between bg-dark-blue p-2 rounded">
          <Link to="/" className="logo d-flex align-items-center">
            {/* Uncomment the line below if you also wish to use an image logo */}
            {/* <img src="assets/img/logo.png" alt="Logo" /> */}
            <img
              width={"200px"}
              className="img-fluid"
              src="https://skans.pk/static/media/Skanslogo1.f87f92f0dafaa656209b.png"
            ></img>
          </Link>
          <nav id="navmenu" className="navmenu">
            <ul>
              <li>
              <Link to="/" className="active">
                  Home
                </Link>
              </li>
              <li>
              <a href="https://skans.pk/SkansGallery">Life@SKANS</a>

              </li>
              <li>
              <Link to="/highAchiever" className='text-danger'>High Achievers</Link>

              </li>
              {/* <li className="dropdown">
                <a href="#">
                  <span>Dropdown</span>{" "}
                  <i className="bi bi-chevron-down toggle-dropdown"></i>
                </a>
                <ul>
                  <li>
                    <a href="#">Dropdown 1</a>
                  </li>
                  <li className="dropdown">
                    <a href="#">
                      <span>Deep Dropdown</span>{" "}
                      <i className="bi bi-chevron-down toggle-dropdown"></i>
                    </a>
                    <ul>
                      <li>
                        <a href="#">Deep Dropdown 1</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 2</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 3</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 4</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 5</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Dropdown 2</a>
                  </li>
                  <li>
                    <a href="#">Dropdown 3</a>
                  </li>
                  <li>
                    <a href="#">Dropdown 4</a>
                  </li>
                </ul>
              </li> */}
              <li>
              <Link to="/Contact">Contact</Link>

              </li>
            </ul>
            <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
          </nav>
        </div>
      </header>
    );
  };
  
  const HeroSection = () => {
    return (
      <section className="hero section dark-background">
        <img
          src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExdW1rbDlzZ3g4bGw4cXdycmU5YXcwb252djl4bTRkZ2p4d2IycXNvdyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/E9nnZZKRTUPpl73BXn/giphy.webp"
          alt="Hero Background"
          className="hero-bg"
        />
        <div className="container">
          <div className="row gy-4 justify-content-between">
            <div
              className="col-lg-4 order-lg-last hero-img"
              // data-aos="zoom-out"
              // data-aos-delay="100"
            >
              <img
                src="https://skans.pk/static/media/Skanslogo1.f87f92f0dafaa656209b.png"
                className="img-fluid animated pt-5"
                alt="Hero"
              />
            </div>
            <div
              className="col-lg-5 d-flex flex-column justify-content-center"
              data-aos="fade-in"
            >
              <h1>
                Welcome to <br></br>
                <span>SKANS SCHOOLS OF ACCOUNTANCY</span>
              </h1>
              <p>
                SKANS is the acronym of an institution, which stands for Skills,
                Knowledge and Application Nurtured through Student-managed
                participative learning approach.
              </p>
              <div className="d-flex">
                <a href="https://skans.pk/SchoolVirtualTour" className="btn-get-started">
                  Visit Schools
                </a>
                {/* <a
                  href="https://www.youtube.com/watch?v=Y7f98aduVJ8"
                  className="glightbox btn-watch-video d-flex align-items-center"
                >
                  <i className="bi bi-play-circle"></i>
                  <span>Watch Video</span>
                </a> */}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://skans.pk/CollegeVirtualTour" className="btn-get-started">
                  Visit College
                </a>
              </div>
            </div>
          </div>
        </div>
        <svg
          className="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            ></path>
          </defs>
          <g className="wave1">
            <use xlinkHref="#wave-path" x="50" y="3"></use>
          </g>
          <g className="wave2">
            <use xlinkHref="#wave-path" x="50" y="0"></use>
          </g>
          <g className="wave3">
            <use xlinkHref="#wave-path" x="50" y="9"></use>
          </g>
        </svg>
      </section>
    );
  };

export default Contact
