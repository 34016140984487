import React from 'react'

function Footer() {
  return (
    <footer id="footer" class="footer dark-background">
    <div class="container footer-top">
      <div class="row gy-4">
        <div class="col-lg-4 col-md-6 footer-about">
          <a  class="logo d-flex align-items-center">
            <span class=""><img className="img-fluid " width={"200px"} src='https://skans.pk/static/media/Skanslogo1.f87f92f0dafaa656209b.png'></img></span>
          </a>
          <div class="footer-contact pt-3">
            <p>
              P26R+524, Nazim-ud-din Rd, F-8 Markaz F 8 Markaz F-8,
              Islamabad, Islamabad Capital Territory 44000
            </p>
            <p class="mt-3">
              <strong>Phone:</strong> <span>+92 3000000000</span>
            </p>
            <p>
              <strong>Email:</strong> <span>info@skans.pk</span>
            </p>
          </div>
          <div class="social-links d-flex mt-4">
            <a href="">
              <i class="bi bi-twitter-x"></i>
            </a>
            <a href="">
              <i class="bi bi-facebook"></i>
            </a>
            <a href="">
              <i class="bi bi-instagram"></i>
            </a>
            <a href="">
              <i class="bi bi-linkedin"></i>
            </a>
          </div>
        </div>





        <div class="col-lg-2 col-md-12 footer-newsletter">
          <h4>Our Schools</h4>

          {/* <p>Subscribe to our newsletter and receive the latest news about our products and services!</p>
<form  method="post" class="php-email-form">
  <div class="newsletter-form"><input type="email" name="email"/><input type="submit" value="Subscribe"/></div>
  <div class="loading">Loading</div>
  <div class="error-message"></div>
  <div class="sent-message">Your subscription request has been sent. Thank you!</div>
</form> */}
<ul>
    <li>
      <a href="https://hscampus.skansschool.edu.pk/">Harley Street Campus</a>
    </li>
    <li>
      <a href="https://golrajuniorcampus.skansschool.edu.pk/">Golra Junior Campus</a>
    </li>
    <li>
      <a href="https://aechscampus.skansschool.edu.pk/">AECHS Campus</a>
    </li>
    <li>
      <a href="https://pwdcampus.skansschool.edu.pk/">PWD Campus</a>
    </li>
    <li>
      <a href="https://westridgeeyecampus.skansschool.edu.pk/">Westridge EYE Campus</a>
    </li>
    <li>
      <a href="https://westridgejuniorcampus.skansschool.edu.pk/">Westridge Junior Campus</a>
    </li>
    <li>
      <a href="https://westridgeseniorcampus.skansschool.edu.pk/">Westridge Senior Campus</a>
    </li>
    <li>
      <a href="http://chakricampus.skansschool.edu.pk/">Chakri Campus</a>
    </li>
    <li>
      <a href="https://golraseniorcampus.skansschool.edu.pk/">Golra Senior Campus</a>
    </li>
  </ul>
        </div>
        <div class="col-lg-2 col-md-3 footer-newsletter">
          <h4>Our Colleges</h4>
          <ul>
            <li>
              <a href="#">Sattelitte Town Campus</a>
            </li>
            <li>
              <a href="#">F8 Markaz Campus</a>
            </li>
            <li>
              <a href="#"> RAR Campus</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-3 footer-links">
          <h4>Affiliations</h4>
          <ul>
            <li>
              <a href="#"></a>
            </li>
            <li>
              <a href="#"></a>
            </li>
            <li>
              <a href="#"></a>
            </li>
            <li>
              <a href="#"></a>
            </li>
            <li>
              <a href="#"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container copyright text-center mt-4">
      <p>
        © <span>Copyright</span>{" "}
        <strong class="px-1 sitename">SKANS</strong>{" "}
        <span>All Rights Reserved</span>
      </p>
      <div class="credits">
        <a href="skans.pk">SKANS</a>
      </div>
    </div>
  </footer>
  )
}

export default Footer