import React from 'react'
// import "../CSS/imagehover.css"
import "./assets/collegetour.css"
import "./assets/schooltourCard.css"
import "./assets/GulzareQuaid.css"


import { Link } from 'react-router-dom'
import NavbarNew from './navbar';
import Footer from './Footer'
// import Sectiontitle1 from '../Photos/section-title-shape-1.png';
// import Sectiontitle2 from '../Photos/section-title-shape-2.png';
// import SchoolNavbar from '../Components/SchoolNavbar';



function CollegeTour() {
    return (


        <div className="index-page">
        <NavbarNew />
        <Header />
        <main className="main">
        <div>
            {/* <SchoolNavbar/> */}
            <div className='VirtualCollegebg'>
                <div className='  container-fluid  ps-lg-5 px-0 text-light filter6 text-center'>
                    <div className='d-flex flex-column'>

                        <div>
                            <span className='fw-bold fs-md-1 fs-2 mb-5'>
                                __________________________
                            </span>
                            <h1 className='text-white fw-bold text-uppercase pt-4 mb-0 d-md-block d-none'><i class="bi bi-stars fs-1 fw-bold pe-3"></i>COLLEGES <i class="bi bi-stars fs-1 fw-bold ps-3"></i></h1>
                            <h2 className='text-white fw-bold text-uppercase pt-4 mb-0 d-md-none d-block'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>COLLEGES <i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h2>


                            <div className='fw-bold fs-md-1 fs-2 text-center'>
                                __________________________
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='container mt-5 lh-lg'>
                <p>At SKANS , we are dedicated to providing quality education and fostering a nurturing environment for young learners. As a renowned institution in the education sector, we pride ourselves on offering comprehensive and well-rounded educational experiences that empower students to excel academically and develop essential life skills.</p>
            </div>
            <section class="light">

  <div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle">Visit Our Campuses (COLLEGES)</div>

		<article class=" postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img" src={require("./assets/F8Campus.png")} alt="Image Title" />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title dark-blue fw-bold"><a href="#">F8 Campus, Islamabad</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="fas fa-calendar-alt mr-2"></i>College
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">14A-Haroon Building F8, Markaz Islamabad</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"> </i>(+92)-51-2287313</li>
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>(+92)-51-2287315</li>
					{/* <li class="tag__item">
						<a href="#"><i class="bi bi-phone mr-2"></i>(+92)-51-5176066</a>
					</li> */}
				</ul>
                {/* <a className='fw-bold' href="https://hscampus.skansschool.edu.pk/">Visit Website</a> */}
			</div>
		</article>
        
		<article class=" postcard light red">

			<div class="postcard__text t-dark">
				<h1 class="postcard__title red fw-bold"><a href="#">RAR Campus</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="bi bi-calendar-alt mr-2"></i>College
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">16-Raja Akram Road, Saddar, Rawalpindi Cantt</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>(+92)-51-512514849</li>
          <li class="tag__item"><i class="bi bi-phone mr-2"></i>(+92)-51-5125155</li>

				</ul>
                {/* <a className='fw-bold' href="https://aechscampus.skansschool.edu.pk/ ">Visit Website</a> */}
			</div>			
            <a class="postcard__img_link" href="#">
				<img class="postcard__img" src={require("./assets/RARCampus.png")} alt="Image Title" />
			</a>
		</article>
		<article class=" postcard light green">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img" src="https://skans.pk/static/media/STCAMPUS.684b3ea82457cd7e5983.png" alt="Image Title" />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title green fw-bold"><a href="#">Satellite Town Campus</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="bi bi-calendar-alt mr-2"></i>College
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">House No. 77-A,Block A,Satellite Town Rehmanabad, Rawalpindi</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>(+92)-51-2716089</li>
				</ul>
                {/* <a className='fw-bold' href="https://golrajuniorcampus.skansschool.edu.pk/">Visit Website</a> */}
			</div>
		</article>
		
	</div>
</section>

        </div>
        </main>
        <Footer/>
        </div>
    )
}
const Header = () => {
    return (
      <header id="header" className="header d-flex align-items-center fixed-top ">
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between bg-dark-blue p-2 rounded">
          <Link to="/" className="logo d-flex align-items-center">
            {/* Uncomment the line below if you also wish to use an image logo */}
            {/* <img src="assets/img/logo.png" alt="Logo" /> */}
            <img
              width={"200px"}
              className="img-fluid"
              src="https://skans.pk/static/media/Skanslogo1.f87f92f0dafaa656209b.png"
            ></img>
          </Link>
          <nav id="navmenu" className="navmenu">
            <ul>
              <li>
                <Link to="/" className="active">
                  Home
                </Link>
              </li>
              <li>
              <a href="https://skans.pk/SkansGallery">Life@SKANS</a>
              </li>
              <li>
              <Link to="/highAchiever" className='text-danger'>High Achievers</Link>

              </li>
              {/* <li className="dropdown">
                <a href="#">
                  <span>Dropdown</span>{" "}
                  <i className="bi bi-chevron-down toggle-dropdown"></i>
                </a>
                <ul>
                  <li>
                    <a href="#">Dropdown 1</a>
                  </li>
                  <li className="dropdown">
                    <a href="#">
                      <span>Deep Dropdown</span>{" "}
                      <i className="bi bi-chevron-down toggle-dropdown"></i>
                    </a>
                    <ul>
                      <li>
                        <a href="#">Deep Dropdown 1</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 2</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 3</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 4</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 5</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Dropdown 2</a>
                  </li>
                  <li>
                    <a href="#">Dropdown 3</a>
                  </li>
                  <li>
                    <a href="#">Dropdown 4</a>
                  </li>
                </ul>
              </li> */}
              <li>
              <Link to="/Contact">Contact</Link>

              </li>
            </ul>
            <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
          </nav>
        </div>
      </header>
    );
  };
  

export default CollegeTour
