import React from 'react'
// import "../CSS/imagehover.css"
import "./assets/collegetour.css"
import "./assets/schooltourCard.css"
import "./assets/GulzareQuaid.css"


import { Link } from 'react-router-dom'
import NavbarNew from './navbar';
import Footer from './Footer'
// import Sectiontitle1 from '../Photos/section-title-shape-1.png';
// import Sectiontitle2 from '../Photos/section-title-shape-2.png';
// import SchoolNavbar from '../Components/SchoolNavbar';



function SchoolTour() {
    return (


        <div className="index-page">
        <NavbarNew />
        <Header />
        <main className="main">
        <div>
            {/* <SchoolNavbar/> */}
            <div className='VirtualSchoolbg'>
                <div className='  container-fluid  ps-lg-5 px-0 text-light filter6 text-center'>
                    <div className='d-flex flex-column'>

                        <div>
                            <span className='fw-bold fs-md-1 fs-2 mb-5'>
                                __________________________
                            </span>
                            <h1 className='text-white fw-bold text-uppercase pt-4 mb-0 d-md-block d-none'><i class="bi bi-stars fs-1 fw-bold pe-3"></i>Schools <i class="bi bi-stars fs-1 fw-bold ps-3"></i></h1>
                            <h2 className='text-white fw-bold text-uppercase pt-4 mb-0 d-md-none d-block'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>Schools <i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h2>


                            <div className='fw-bold fs-md-1 fs-2 text-center'>
                                __________________________
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='container mt-5 lh-lg'>
                <p>At SKANS , we are dedicated to providing quality education and fostering a nurturing environment for young learners. As a renowned institution in the education sector, we pride ourselves on offering comprehensive and well-rounded educational experiences that empower students to excel academically and develop essential life skills.</p>
            </div>
            <section class="light">
	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle">Visit Our Campuses (SCHOOLS)</div>

		<article class=" postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img" src="https://skans.pk/static/media/HarleyCampus.dd1ead3edfb2133c6bfa.png" alt="Image Title" />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title dark-blue fw-bold"><a href="#">Harley Street Campus</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="fas fa-calendar-alt mr-2"></i>Junior School
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">House No 53-B Harley Street Campus – Rawalpindi</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"> </i>  (+92)-332-8922225</li>
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>(+92)-51-5176138</li>
					<li class="tag__item">
						<a href="#"><i class="bi bi-phone mr-2"></i>(+92)-51-5176066</a>
					</li>
				</ul>
                <a className='fw-bold' href="https://hscampus.skansschool.edu.pk/">Visit Website</a>
			</div>
		</article>
        
		<article class=" postcard light red">

			<div class="postcard__text t-dark">
				<h1 class="postcard__title red fw-bold"><a href="#">AECHS Campus</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="bi bi-calendar-alt mr-2"></i>Junior School
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">337 Street 17, Sector 1 Airport Employees CHS, Rawalpindi</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>(051) 8468475</li>

				</ul>
                <a className='fw-bold' href="https://aechscampus.skansschool.edu.pk/ ">Visit Website</a>
			</div>			
            <a class="postcard__img_link" href="#">
				<img class="postcard__img" src="https://skans.pk/static/media/AECHSCampus.5e09fde8ca5b15a21845.png" alt="Image Title" />
			</a>
		</article>
		<article class=" postcard light green">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img" src={require("./assets/golraCampus.png")} alt="Image Title" />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title green fw-bold"><a href="#">Golra Mor Campus</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="bi bi-calendar-alt mr-2"></i>Junior School
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Moaz Rd, Main Peshawar Rd, near Jamia Masjid Suqaina Golrah Morh, Islamabad</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>(051) 2227475</li>
				</ul>
                <a className='fw-bold' href="https://golrajuniorcampus.skansschool.edu.pk/">Visit Website</a>
			</div>
		</article>
		<article class="postcard light yellow">

			<div class="postcard__text t-dark">
				<h1 class="postcard__title yellow fw-bold"><a href="#">Chakri Road Campus</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						<i class="bi bi-calendar-alt mr-2"></i>Junior School
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Raja Bashir Plaza, Moza Hayal Ranail, Opposite Pir Mehar Ali Shah Town, Chakri Road Rawalpindi.</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>0310-3331131</li>
					<li class="tag__item"><i class="bi bi-phone mr-2"></i>051- 5575082</li>
				</ul>
                <a className='fw-bold' href="http://chakricampus.skansschool.edu.pk/">Visit Website</a>
			</div>			
            <a class="postcard__img_link" href="#">
				<img class="postcard__img" src="	https://skans.pk/static/media/ChakriCampus.00a19e1fcc8c20aa2319.png" alt="Image Title" />
			</a>
		</article>
    <article class="postcard light yellow">
      <a class="postcard__img_link" href="#">
  <img class="postcard__img" src={require("./assets/juniorWestridge.png")} alt="Image Title" />
</a>
<div class="postcard__text t-dark">
  <h1 class="postcard__title yellow fw-bold"><a href="#">Junior Westridge Campus</a></h1>
  <div class="postcard__subtitle small">
    <time datetime="2020-05-25 12:00:00">
      <i class="bi bi-calendar-alt mr-2"></i>Junior School
    </time>
  </div>
  <div class="postcard__bar"></div>
  <div class="postcard__preview-txt">H.No. 9-B, Hali Road, Westridge-1, Rawalpindi</div>
  <ul class="postcard__tagbox">
    <li class="tag__item"><i class="bi bi-phone mr-2"></i>051-8773091</li>
    {/* <li class="tag__item"><i class="bi bi-phone mr-2"></i>051- 5575082</li> */}
  </ul>
          <a className='fw-bold' href="https://westridgejuniorcampus.skansschool.edu.pk/">Visit Website</a>
</div>			

</article>

<article class="postcard light yellow">

<div class="postcard__text t-dark">
  <h1 class="postcard__title yellow fw-bold"><a href="#">Senior Westridge Campus</a></h1>
  <div class="postcard__subtitle small">
    <time datetime="2020-05-25 12:00:00">
      <i class="bi bi-calendar-alt mr-2"></i>Senior School
    </time>
  </div>
  <div class="postcard__bar"></div>
  <div class="postcard__preview-txt">91 Hali Road, Street no. 8, Westridge-1, Rawalpindi</div>
  <ul class="postcard__tagbox">
    <li class="tag__item"><i class="bi bi-phone mr-2"></i>(051) 8355546</li>
    {/* <li class="tag__item"><i class="bi bi-phone mr-2"></i>051- 5575082</li> */}
  </ul>
          <a className='fw-bold' href=" https://westridgeseniorcampus.skansschool.edu.pk/">Visit Website</a>
</div>	
<a class="postcard__img_link" href="#">
  <img class="postcard__img" src="https://skans.pk/static/media/SeniorWestridgeCampus.3dd96887a59e813a4623.png" alt="Image Title" />
</a>		

</article>

<article class="postcard light yellow">
<a class="postcard__img_link" href="#">
  <img class="postcard__img" src={require("./assets/PWDCampus.png")} alt="Image Title" />
</a>		
<div class="postcard__text t-dark">
  <h1 class="postcard__title yellow fw-bold"><a href="#">PWD Campus</a></h1>
  <div class="postcard__subtitle small">
    <time datetime="2020-05-25 12:00:00">
      <i class="bi bi-calendar-alt mr-2"></i>Junior School
    </time>
  </div>
  <div class="postcard__bar"></div>
  <div class="postcard__preview-txt">233, Block A Sector A PWD, Islamabad, Punjab</div>
  <ul class="postcard__tagbox">
    <li class="tag__item"><i class="bi bi-phone mr-2"></i>(051) 8355546</li>
    {/* <li class="tag__item"><i class="bi bi-phone mr-2"></i>051- 5575082</li> */}
  </ul>
          <a className='fw-bold' href="https://pwdcampus.skansschool.edu.pk/">Visit Website</a>
</div>	


</article>
	</div>
</section>

        </div>
        </main>
        <Footer/>
        </div>
    )
}
const Header = () => {
    return (
      <header id="header" className="header d-flex align-items-center fixed-top ">
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between bg-dark-blue p-2 rounded">
          <Link to="/" className="logo d-flex align-items-center">
            {/* Uncomment the line below if you also wish to use an image logo */}
            {/* <img src="assets/img/logo.png" alt="Logo" /> */}
            <img
              width={"200px"}
              className="img-fluid"
              src="https://skans.pk/static/media/Skanslogo1.f87f92f0dafaa656209b.png"
            ></img>
          </Link>
          <nav id="navmenu" className="navmenu">
            <ul>
              <li>
                <Link to="/" className="active">
                  Home
                </Link>
              </li>
              <li>
              <a href="https://skans.pk/SkansGallery">Life@SKANS</a>
              </li>
              <li>
              <Link to="/highAchiever" className='text-danger'>High Achievers</Link>

              </li>
              {/* <li className="dropdown">
                <a href="#">
                  <span>Dropdown</span>{" "}
                  <i className="bi bi-chevron-down toggle-dropdown"></i>
                </a>
                <ul>
                  <li>
                    <a href="#">Dropdown 1</a>
                  </li>
                  <li className="dropdown">
                    <a href="#">
                      <span>Deep Dropdown</span>{" "}
                      <i className="bi bi-chevron-down toggle-dropdown"></i>
                    </a>
                    <ul>
                      <li>
                        <a href="#">Deep Dropdown 1</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 2</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 3</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 4</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 5</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Dropdown 2</a>
                  </li>
                  <li>
                    <a href="#">Dropdown 3</a>
                  </li>
                  <li>
                    <a href="#">Dropdown 4</a>
                  </li>
                </ul>
              </li> */}
              <li>
              <Link to="/Contact">Contact</Link>

              </li>
            </ul>
            <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
          </nav>
        </div>
      </header>
    );
  };
  
  const HeroSection = () => {
    return (
      <section className="hero section dark-background">
        <img
          src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExdW1rbDlzZ3g4bGw4cXdycmU5YXcwb252djl4bTRkZ2p4d2IycXNvdyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/E9nnZZKRTUPpl73BXn/giphy.webp"
          alt="Hero Background"
          className="hero-bg"
        />
        <div className="container">
          <div className="row gy-4 justify-content-between">
            <div
              className="col-lg-4 order-lg-last hero-img"
              // data-aos="zoom-out"
              // data-aos-delay="100"
            >
              <img
                src="https://skans.pk/static/media/Skanslogo1.f87f92f0dafaa656209b.png"
                className="img-fluid animated pt-5"
                alt="Hero"
              />
            </div>
            <div
              className="col-lg-5 d-flex flex-column justify-content-center"
              data-aos="fade-in"
            >
              <h1>
                Welcome to <br></br>
                <span>SKANS SCHOOLS OF ACCOUNTANCY</span>
              </h1>
              <p>
                SKANS is the acronym of an institution, which stands for Skills,
                Knowledge and Application Nurtured through Student-managed
                participative learning approach.
              </p>
              <div className="d-flex">
                <a href="https://skans.pk/SchoolVirtualTour" className="btn-get-started">
                  Visit Schools
                </a>
                {/* <a
                  href="https://www.youtube.com/watch?v=Y7f98aduVJ8"
                  className="glightbox btn-watch-video d-flex align-items-center"
                >
                  <i className="bi bi-play-circle"></i>
                  <span>Watch Video</span>
                </a> */}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://skans.pk/CollegeVirtualTour" className="btn-get-started">
                  Visit College
                </a>
              </div>
            </div>
          </div>
        </div>
        <svg
          className="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            ></path>
          </defs>
          <g className="wave1">
            <use xlinkHref="#wave-path" x="50" y="3"></use>
          </g>
          <g className="wave2">
            <use xlinkHref="#wave-path" x="50" y="0"></use>
          </g>
          <g className="wave3">
            <use xlinkHref="#wave-path" x="50" y="9"></use>
          </g>
        </svg>
      </section>
    );
  };

export default SchoolTour
