import React, { useEffect, useState } from "react";
import "./assets/MeritStudents.css";
import "./assets/MeritSlider.css";

// import Navbar from '../Components/navbar';
// import Sectiontitle1 from '../Photos/section-title-shape-1.png';
// import Sectiontitle2 from '../Photos/section-title-shape-2.png';
// import MeritList from '../Photos/MeritListVector.jpg';
// import ReactPaginate from 'react-paginate';
// import { backendURL } from '../constant';
import HighachieverNavbar from "./HighachieverNavbar";
// import CollegeNavbar from '../Components/CollegeNavbar';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "remixicon/fonts/remixicon.css";
import Footer from "./Footer";
import Slider from "./multiCarousel";
import MainSlider from "./mainslider";

function MeritStudents() {
  const [details, setDetails] = useState([]);
  //   const picUrl = `${backendURL}/public/uploads/`;
  const [currentPage, setCurrentPage] = useState(0);
  const universitiesPerPage = 12;

  const callProfile = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow",
      };

      fetch(`/getHighAchievers`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("getHighAchievers", result);

          const data = JSON.parse(result);
          console.log(data);
          setDetails(data);
        })
        .catch((error) => console.error(error));
      // const res = await fetch('/getHighAchievers', {
      //   method: 'GET',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json',
      //   },
      //   credentials: 'include',
      // });

      // if (res.status === 200) {
      //   const data = await res.json();
      //   setDetails(data);
      // } else {
      //   const error = new Error(res.statusText);
      //   throw error;
      // }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    callProfile();
  }, []);

  const indexOfLastUniversity = (currentPage + 1) * universitiesPerPage;
  const indexOfFirstUniversity = indexOfLastUniversity - universitiesPerPage;
  const currentDetail = details.slice(
    indexOfFirstUniversity,
    indexOfLastUniversity
  );
  //   const cards = [
  //     {
  //       id: 1,
  //       name: "Kell Dawx",
  //       image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbsWQ8fklMqxF9OrID73RBaPSASzjzKhnCPrqxAfwpIO_0uo2262AAGXe7ByFTX0fVoD8&usqp=CAU",
  //       description: "Passionate about development and design, I carry out projects at the request of users."
  //     },
  //     {
  //       id: 2,
  //       name: "Lotw Fox",
  //       image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbsWQ8fklMqxF9OrID73RBaPSASzjzKhnCPrqxAfwpIO_0uo2262AAGXe7ByFTX0fVoD8&usqp=CAU",
  //       description: "Passionate about development and design, I carry out projects at the request of users."
  //     },
  //     {
  //       id: 3,
  //       name: "Sara Mit",
  //       image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbsWQ8fklMqxF9OrID73RBaPSASzjzKhnCPrqxAfwpIO_0uo2262AAGXe7ByFTX0fVoD8&usqp=CAU",
  //       description: "Passionate about development and design, I carry out projects at the request of users."
  //     },
  //     {
  //       id: 4,
  //       name: "Jenny Wert",
  //       image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbsWQ8fklMqxF9OrID73RBaPSASzjzKhnCPrqxAfwpIO_0uo2262AAGXe7ByFTX0fVoD8&usqp=CAU",
  //       description: "Passionate about development and design, I carry out projects at the request of users."
  //     },
  //     {
  //       id: 5,
  //       name: "Lexa Kin",
  //       image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbsWQ8fklMqxF9OrID73RBaPSASzjzKhnCPrqxAfwpIO_0uo2262AAGXe7ByFTX0fVoD8&usqp=CAU",
  //       description: "Passionate about development and design, I carry out projects at the request of users."
  //     }
  //   ];
  const cards = [
    {
      id: 1,
      name: "Usama Rahim",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbsWQ8fklMqxF9OrID73RBaPSASzjzKhnCPrqxAfwpIO_0uo2262AAGXe7ByFTX0fVoD8&usqp=CAU",

      course: "ACCA",
      paper: "F8 Audit and Assurance",
      percentage: "89%",
      achievement: "World Prize Winner",
      description:
        "Achieved highest marks in F8 paper in audit planning and risk assessment.",
    },
    {
      id: 2,
      name: "Ahmed Ali",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbsWQ8fklMqxF9OrID73RBaPSASzjzKhnCPrqxAfwpIO_0uo2262AAGXe7ByFTX0fVoD8&usqp=CAU",

      course: "CA",
      paper: "Advanced Accounting",
      percentage: "92%",
      achievement: "National Topper",
      description:
        "Secured first position in Advanced Accounting in consolidated financial statements.",
    },
    {
      id: 3,
      name: "Hamza Khan",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbsWQ8fklMqxF9OrID73RBaPSASzjzKhnCPrqxAfwpIO_0uo2262AAGXe7ByFTX0fVoD8&usqp=CAU",

      course: "ACCA",
      paper: "P2 Corporate Reporting",
      percentage: "85%",
      achievement: "Regional Top Scorer",
      description:
        "Distinguished performance in corporate reporting with specialized focus on IFRS implementation.",
    },
    {
      id: 4,
      name: "Ayesha Butt",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbsWQ8fklMqxF9OrID73RBaPSASzjzKhnCPrqxAfwpIO_0uo2262AAGXe7ByFTX0fVoD8&usqp=CAU",

      course: "CA",
      paper: "Strategic Financial Management",
      percentage: "94%",
      achievement: "Gold Medalist",
      description:
        "Exceptional understanding of strategic financial planning and corporate valuations.",
    },
    {
      id: 5,
      name: "Zain Malik",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbsWQ8fklMqxF9OrID73RBaPSASzjzKhnCPrqxAfwpIO_0uo2262AAGXe7ByFTX0fVoD8&usqp=CAU",

      course: "ACCA",
      paper: "F9 Financial Management",
      percentage: "88%",
      achievement: "Merit Award",
      description:
        "Outstanding grasp of investment appraisal and business finance principles.",
    },
  ];

  return (
    <>
      {/* <CollegeNavbar /> */}
      <HighachieverNavbar />
      <div className="Meritlistbg mb-5">
        <div className="container-fluid ps-5 text-light filter12 text-center">
          <p></p>
          <br />
          <h1 className="text-white fw-bold d-sm-block d-none"></h1>
          <h1 className="text-white fw-bold fs-1 d-sm-none d-block">

          </h1>
        </div>
      </div>

      <div className="container">
        <div
          className="bg-dark-blue text-white fw-bold p-3 mt-5 mb-lg-0 mb-5 text-center rounded"
          data-aos="flip-right"
        >
          <h3 className="fw-bold text-white  d-sm-block d-none">
            Meet the Brightest Stars of SKANS School of Accountancy!
          </h3>
          <h5 className="fw-bold text-white  d-sm-none d-block">
            Meet the Brightest Stars of SKANS School of Accountancy!
          </h5>
        </div>
        <div className="d-flex flex-wrap align-items-center" data-aos="fade-up">
          <div
            className="col-lg-8 col-12 text-justify lh-lg"
            data-aos="fade-right"
          >
            Welcome to{" "}
            <span
              className="fontcolordark fw-bold"
              style={{ fontFamily: "-moz-initial" }}
            >
              SKANS
            </span>{" "}
            celebrating the exceptional achievements in the field of accountancy
            - the Merit List of Top Position Students. These individuals are not
            just students; they are our pride, our stars, who have illuminated
            the realm of accountancy with their unparalleled dedication and
            brilliance. Securing the top positions, they have showcased their
            unwavering commitment to academic excellence and a profound
            understanding of the intricate world of numbers. Their remarkable
            accomplishments stand as a testament to their hard work,
            perseverance, and intellectual prowess. Join us in acknowledging
            these outstanding individuals who have risen above the rest,
            inspiring us all and setting new standards of achievement in the
            realm of accountancy.
          </div>
          <div className="col-lg-4 col-10" data-aos="fade-left">
            <img
              className="img-fluid"
              src="https://skans.pk/static/media/MeritListVector.5cfa9731bfb557543887.jpg"
              alt="Merit List"
            />
          </div>
        </div>
        {/* <div className="d-flex align-items-center" data-aos="fade-up">
          <div className="b"></div>
          <div data-aos="zoom-in" className="my-5 d-flex align-items-center justify-content-center border p-3 bg-dark-blue rounded px-5">

            <h3 className="text-light fw-bold mb-0 text-uppercase text-center">MERIT LIST</h3>&nbsp;&nbsp;&nbsp;&nbsp;

          </div>
          <div className="b"></div>
        </div> */}
      </div>

      {/* <div className="container-fluid">
        <ul className="three">
          {currentDetail.length > 0 ? (
            currentDetail.map((ann, id) => (
              <li key={id} className="transition col-3" data-aos="flip-left">
                <div className="wrapper">
                  <img className="transition rounded-circle" width={"40%"} src={picUrl + ann.photos} alt={ann.name} />
                  <ul className="social">
                    <li>{ann.description}</li>
                  </ul>
                  <span className="transition">
                    <h3>{ann.name}</h3>
                    <img className="mamber-img rounded-circle" width={"40%"} src={picUrl + ann.photos} alt={ann.name} />
                    <h3>{ann.name}</h3>
                    <i>more</i>
                  </span>
                </div>
              </li>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </ul>

        <ReactPaginate
                pageCount={Math.ceil(details.length / universitiesPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                activeClassName="active"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                previousLabel={<i className="bi bi-chevron-left"></i>}
                nextLabel={<i className="bi bi-chevron-right"></i>}
            />
      </div> */}
      {/* <section className="container-fluid bg-light">
        <h1 className='fw-bold text-center mb-5'>SKANS High Achievers 2023</h1>
        <Swiper
    modules={[Navigation, Pagination, Autoplay]}
    slidesPerView={2}
    centeredSlides={false}
    loop={true}
    spaceBetween={32}
    autoplay={{
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    }}
    navigation={{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }}
    pagination={{
      el: '.swiper-pagination',
      clickable: true,
    }}
    breakpoints={{
      // Responsive breakpoints
      320: { slidesPerView: 1, spaceBetween: 16 }, // Mobile screens
      768: { slidesPerView: 1, spaceBetween: 24 }, // Tablets
      1024: { slidesPerView: 2, spaceBetween: 32 }, // Laptops
    }}
    className="mySwiper"
  >

        <div className="card__content bg-white">
          {cards.map(card => (
            <SwiperSlide key={card.id} className=" bg-white card__article d-flex shadow-sm">

                <img src={card.image} alt="avatar" className="col-4 card__img" />

              <div className="card__data">
  <h3 className=" dark-blue">{card.name}</h3>
  <div className="card__details">
    <p className="card__course">{card.course}</p>
    <p className="card__paper">{card.paper}</p>
    <p className="card__percentage">{card.percentage}</p>
  </div>
  <p className="card__description">{card.description}</p>
</div>
            </SwiperSlide>
          ))}
        </div>

        <div className="swiper-button-next">

        </div>
        
        <div className="swiper-button-prev">
        </div>

        <div className="swiper-pagination"></div>
      </Swiper>
    </section> */}

      {/* <section className="container-fluid bg-light">
        <h1 className='fw-bold text-center mb-5'>SKANS High Achievers 2024</h1>
        <Swiper
    modules={[Navigation, Pagination, Autoplay]}
    slidesPerView={2}
    centeredSlides={false}
    loop={true}
    spaceBetween={32}
    autoplay={{
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    }}
    navigation={{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }}
    pagination={{
      el: '.swiper-pagination',
      clickable: true,
    }}
    breakpoints={{
   
      320: { slidesPerView: 1, spaceBetween: 16 }, // Mobile screens
      768: { slidesPerView: 1, spaceBetween: 24 }, // Tablets
      1024: { slidesPerView: 2, spaceBetween: 32 }, // Laptops
    }}
    className="mySwiper"
  >

        <div className="card__content bg-white">
          {cards.map(card => (
            <SwiperSlide key={card.id} className=" bg-white card__article d-flex shadow-sm">
                <img src={card.image} alt="avatar" className="col-4 card__img" />

              <div className="card__data">
  <h3 className=" dark-blue">{card.name}</h3>
  <div className="card__details">
    <p className="card__course">{card.course}</p>
    <p className="card__paper">{card.paper}</p>
    <p className="card__percentage">{card.percentage}</p>
  </div>
  <p className="card__description">{card.description}</p>
</div>
            </SwiperSlide>
          ))}
        </div>

        <div className="swiper-button-next">
        </div>
        
        <div className="swiper-button-prev">
        </div>

        <div className="swiper-pagination"></div>
      </Swiper>
    </section> */}
      <div className="d-flex mb-5">
        <div className="col-6">
          <div
            data-aos="zoom-in"
            className="my-5 d-flex align-items-center justify-content-center border p-3 bg-dark-blue rounded px-5"
          >
            <h3 className="text-light fw-bold mb-0 text-uppercase text-center">
              2023 Batch
            </h3>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          {/* <h1 className='fw-bold text-center'>2023 Batch</h1> */}
          <Slider />
        </div>
        <div className="col-6">
          <div
            data-aos="zoom-in"
            className="my-5 d-flex align-items-center justify-content-center border p-3 bg-dark-blue rounded px-5"
          >
            <h3 className="text-light fw-bold mb-0 text-uppercase text-center">
              2024 Batch
            </h3>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div>

          {/* <h1 className='fw-bold text-center'>2024 Batch</h1> */}
          <Slider></Slider>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default MeritStudents;
